import AttendanceBaseService from '../base/AttendanceBaseService';

export class WorkflowApi extends AttendanceBaseService {
    constructor() {
        super("workflow");
    }

    /**
      * 任务列表
     */
    public getTaskList(params: any): Promise<any> {
        return this.request('task/list', this.rest.METHOD_GET, params);
    }
    public getProcInfo(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
    /**
   * 流程发送
  */
    public send(params: any): Promise<any> {
        return this.request('send?procId=' + params.procId + '&taskId=' + params.taskId + '&option=' + params.option, this.rest.METHOD_POST, params.listReciveVar);
    }
    /**
     * 获取下一步骤用户
     */
    public getNextStepUsers(params: any): Promise<any> {
        return this.request('nextStepUsers', this.rest.METHOD_GET, params);
    }
    /**
     * 流程环节是否已完成,true已处理，false未处理
     */
    public isDealDoneWorkTask(params: any): Promise<any> {
        return this.request('isDeal', this.rest.METHOD_GET, params);
    }
    /**
     * 是否可以撤回
     */
    public canRollBackWorkTask(params: any): Promise<any> {
        return this.request('canRollback', this.rest.METHOD_GET, params);
    }
    /**
     * 撤回流程  
     */
    public forceStopWorkTask(params: any): Promise<any> {
        return this.request('stopProcess?procId=' + params.procId, this.rest.METHOD_POST, {});
    }
    /**
     * 待办列表
     */
    public getTodoList(params: any): Promise<any> {
        return this.request('todo/list', this.rest.METHOD_GET, params);
    }
    /**
   * 已办列表
   */
    public getDoneList(params: any): Promise<any> {
        return this.request('done/list', this.rest.METHOD_GET, params);
    }
    //一键审批
    public sendBatch(params: any): Promise<any> {
        return this.request('send/batch', this.rest.METHOD_POST, params);
    }
}
import { TravelApi } from '@/api/attendance/travel/TravelApi';

export class TravelService {
    private api = new TravelApi();

    //提交
    public submit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.submit(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //出行方式列表
    public getWayList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getWayList().then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //获取详情 
    public getInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //上传附件 
    public getUploadFilePath(): string {
        return this.api.getUploadFilePath();
    }
    //删除附件 
    public deleteFile(path: any,id:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.deleteFile(path,id).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //完成购票
    public doneTicket(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.doneTicket(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}

import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import moment from "moment";
import regExps from "@/utils/regExps";
import SystemUtils from "@/utils/SystemUtils";
import { TravelService } from "@/services/attendance/travel/TravelService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
export default defineComponent({
  name: "travelApply",
  components: {
    Header,
  },
  data() {
    return {
      router: useRouter(),
      api: new TravelService(),
      workflowService: new WorkflowService(),
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      info: Object.create(null),
      travelList: new Array<any>(0),
      wayList: new Array<any>(0),
      isUser: true,
      userInfo: Object.create(null),
    };
  },
  mounted() {
    this.userInfo = SystemUtils.loginUser;
    this.initData();
  },
  methods: {
    initData() {
      this.info = {
        startNoon: "AM",
        startTime: "",
        endNoon: "AM",
        endTime: "",
        id: "",
        needTicket: 1, //0不需要  1需要
        procId: "",
        reason: "",
        source: 0,
        taskId: "",
        travelArrive: "",
        travelFrom: "",
        travelUser: "",
        travelUserIdnumber: "",
        travelUserTel: "",
        travelWayId: "nes",
        travelWayName: "飞机",
      };
      this.api.getWayList().then((res: any) => {
        this.wayList = res;
      });
      this.getTravelList();
    },
    getTravelList() {
      let obj = {
        date: "",
        NoonText: "上午",
        travelWayId: this.info.travelWayId,
        travelWayName: this.info.travelWayName,
        travelFrom: "",
        travelArrive: "",
        needTicket: this.info.needTicket,
        attachments: [],
      };
      this.travelList.push(obj);
    },
    //开始日期
    selectStartNoon() {
      let selectOperate: any = document.getElementById("start_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindStartNoon(ev: any) {
      this.info.startNoon = ev;
    },
    //结束日期
    selectEndNoon() {
      let selectOperate: any = document.getElementById("end_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindEndNoon(ev: any) {
      this.info.endNoon = ev;
    },
    //出行方式
    selectWay() {
      let selectOperate: any = document.getElementById("select_way");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindWay(ev: any, index: any) {
      console.log(index);

      this.travelList[index].travelWayId = ev;
      for (let way of this.wayList) {
        if (way.id == ev) {
          this.travelList[index].travelWayName = way.name;
          this.travelList[index].needTicket = way.needTicket;
        }
      }
    },
    //是否需要购票，若需要则显示用户信息
    trip(type: any, index: any) {
      if (type == false) {
        this.travelList[index].needTicket = 0;
      } else {
        this.travelList[index].needTicket = 1;
      }
      if (this.travelList.filter((item) => item.needTicket == 1).length > 0) {
        this.isUser = true;
      } else {
        this.isUser = false;
      }
    },
    //添加行程
    addParagraph() {
      this.getTravelList();
      let i = this.travelList.length - 1;
      setTimeout(() => {
        let a: any = document.querySelector("#root" + i);
        a.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }, 500);
    },
    //删除行程
    removeParagraph(index: number) {
      this.travelList.splice(index, 1);
      if (this.travelList.filter((item) => item.needTicket == 1).length > 0) {
        this.isUser = true;
      } else {
        this.isUser = false;
      }
    },
    //保存
    saveHandler() {
      if (this.info.startTime == "") {
        Utils.presentToastWarning("请选择出差开始时间");
        return;
      }
      if (this.info.endTime == "") {
        Utils.presentToastWarning("请选择出差结束时间");
        return;
      }
      if (this.info.reason.replace(/\s+/g, "") == "") {
        Utils.presentToastWarning("请输入出差原因");
        return;
      }

      if (regExps.SpecChar.test(this.info.reason)) {
        Utils.presentToastWarning("出差原因不能输入特殊字符哦！");
        return;
      }

      this.info.startTime = moment(this.info.startTime).format(
        "YYYY-MM-DD 00:00:00"
      );
      this.info.endTime = moment(this.info.endTime).format(
        "YYYY-MM-DD 00:00:00"
      );
      if (this.info.startTime > this.info.endTime) {
        Utils.presentToastWarning("出差开始时间不能晚于出差结束时间");
        return;
      }
      if (this.info.startTime == this.info.endTime) {
        if (this.info.startNoon == "PM" && this.info.endNoon == "AM") {
          Utils.presentToastWarning("出差开始时间不能晚于出差结束时间");
          return;
        }
      }
      if (this.isUser) {
        if (this.userInfo.name.replace(/\s+/g, "") == "") {
          Utils.presentToastWarning("请输入出差人姓名");
          return;
        }
        if (regExps.SpecChar.test(this.userInfo.name)) {
          Utils.presentToastWarning("请输入正确的姓名");
          return;
        }
        if (!regExps.phoneReg.test(this.userInfo.phone)) {
          Utils.presentToastWarning("请输入正确的手机号");
          return;
        }
        if (regExps.idNumberReg.test(this.userInfo.userId)) {
          console.log(regExps.idNumberReg.test(this.userInfo.userId));
          Utils.presentToastWarning("请输入正确的身份证号");
          return;
        }
      }
      let flag = true;
      this.travelList.forEach((item) => {
        if (item.date == "") {
          Utils.presentToastWarning("请选择出行日期");
          flag = false;
        }
        item.date = moment(item.date).format("YYYY-MM-DD 00:00:00");
        if (item.travelFrom.replace(/\s+/g, "") == "") {
          Utils.presentToastWarning("请输入出发地点");
          flag = false;
        }
        if (item.travelArrive.replace(/\s+/g, "") == "") {
          Utils.presentToastWarning("请输入目的地点");
          flag = false;
        }
        item.needTicket = item.needTicket ? 1 : 0;
      });
      if (flag) {
        let that = this;
        Utils.confirmDialog("确认", "请确认是否提交出差申请", function () {
          that.submit();
        });
      }
    },
    //提交
    submit() {
      this.info.travelUserIdnumber = this.userInfo.userId;
      this.info.travelUser = this.userInfo.name;
      this.info.travelUserTel = this.userInfo.phone;
      this.info.trips = this.travelList;
      this.api
        .submit(this.info)
        .then((res: any) => {
          let paramSend = {
            procId: res.procId,
            taskId: res.taskId,
            listReciveVar: [],
            option: "",
          };
          this.workflowService
            .send(paramSend)
            .then((result: any) => {
              if (result) {
                if (result.result) {
                  Utils.presentToast("已成功提交出差申请！");
                  setTimeout(() => {
                    this.router.go(-1);
                  }, 500);
                }
              }
            })
            .catch((ex: any) => {});
        })
        .catch(() => {});
    },
    userName() {
      setTimeout(() => {
        let a: any = document.querySelector("#userName");
        a.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }, 500);
    },
    userPhone() {
      setTimeout(() => {
        let a: any = document.querySelector("#userPhone");
        a.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }, 500);
    },
    userId() {
      setTimeout(() => {
        let a: any = document.querySelector("#userId");
        a.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }, 500);
    },
  },
});

import { WorkflowApi } from '@/api/attendance/workflow/WorkflowApi';

export class WorkflowService {
    private api = new WorkflowApi();

    public getTaskList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getTaskList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getProcInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getProcInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public send(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.send(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err.data.error);
            });
        });
    }
    public getNextStepUsers(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getNextStepUsers(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public isDealDoneWorkTask(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.isDealDoneWorkTask(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public canRollBackWorkTask(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.canRollBackWorkTask(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public forceStopWorkTask(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.forceStopWorkTask(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //代办列表 
    public getTodoList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getTodoList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //已办列表 
    public getDoneList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDoneList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //一键审批  
    public sendBatch(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.sendBatch(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

}